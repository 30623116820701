import 'main/webpack-runtime-settings.js';
import 'util/fixes';
import initSentry from './sentry';
import { installPolyfills, handleErrors } from 'util/polyfills';
import 'custom-event-polyfill';
import { settings, SELECT_ICON, CHECKBOX_ICON } from '@dreipol/vue-ui/src/settings';

// remove the no-js from the DOM Root
document.documentElement.classList.remove('no-js');

// Vue Ui icons settings
settings.set(SELECT_ICON, {
    symbol: 'chevron_down',
    size: 'medium',
});

settings.set(CHECKBOX_ICON, {
    symbol: 'checkmark',
    size: 'small',
});

window.googleRecaptchaCallback = function() {
    globals.state.googleRecaptchaLoaded = true;
    window.dispatchEvent(new CustomEvent('recaptcha-loaded'));
};

/**
 * Main entry point to the js app. Javascript execution starts here
 * 1. Webpack config and general fixes are initialized via `import` statement
 * 2. Polyfills are imported and asynchronously processed via `Promise` API
 * 3. In case of success, the vue app is initialized
 */
installPolyfills
    .then(() => globals.config.debug ? null : initSentry())
    .then(() => require.ensure([], () => require('framework/base'), null, 'app-boot')) // eslint-disable-line global-require
    .then(app => app.init())
    .catch(handleErrors);
