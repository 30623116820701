/* eslint-disable global-require */
export default [
    {
        hasNativeSupport: () => typeof Array.from === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.array.from'));
            }, null, 'polyfills/array-from');
        },
    },
    {
        hasNativeSupport: () => typeof CSS !== 'undefined' && CSS.supports && CSS.supports('object-fit', 'cover'),
        polyfill(resolve) {
            require.ensure([], () => {
                const objectFit = require('object-fit-images');
                objectFit();
                resolve();
            }, null, 'polyfills/object-fit-images');
        },
    },
    {
        hasNativeSupport: () => typeof Array.of === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.array.of'));
            }, null, 'polyfills/array-of');
        },
    },

    {
        hasNativeSupport: () => !!window.IntersectionObserver,
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('intersection-observer'));
                IntersectionObserver.prototype.POLL_INTERVAL = 100;
            }, null, 'polyfills/intersection-observer');
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.includes === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.array.includes'));
            }, null, 'polyfills/array-includes');
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.find === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.array.find'));
            }, null, 'polyfills/array-find');
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.findIndex === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.array.find-index'));
            }, null, 'polyfills/array-find-index');
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.fill === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.array.fill'));
            }, null, 'polyfills/array-fill');
        },
    },
    {
        hasNativeSupport: () => typeof Object.assign === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.object.assign'));
            }, null, 'polyfills/object-assign');
        },
    },
    {
        hasNativeSupport: () => typeof Object.entries === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.object.entries'));
            }, null, 'polyfills/object-entries');
        },
    },
];
